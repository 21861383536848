import React, { Suspense, lazy, useEffect } from "react";
import { Redirect, Switch } from "react-router-dom";

import AuthorizeRoute from "./AuthorizeRoute";
import { LayoutSplashScreen } from "../_metronic/layout";
import { useDispatch, useSelector } from "react-redux";

import preferenceActions from "@actions/preferenceActions";
import organizationActions from "@actions/organizationActions";
import { isEmpty } from "lodash";

const OrganizationInfo = lazy(() => import('./modules/Organization/pages/info'));
const ApplicationMenuPage = lazy(() => import('./modules/ApplicationMenu/pages'));
const ApplicationUserPage = lazy(() => import('./modules/Settings/pages/ApplicationUser'));

const OrganizationsPage = lazy(() => import('./modules/Settings/pages/organization'));
const OrganizationDetailPage = lazy(() => import('./modules/Settings/pages/organization/DetailPage'));

const UserProfile = lazy(() => import('./modules/Profiles/pages/profile'));
const UserDetailPage = lazy(() => import('./modules/Settings/pages/ApplicationUser/detail'));
const FormEditorPage = lazy(() => import("./modules/FormEditor/pages"));
const AppEditorPage = lazy(() => import("./pages/AppEditorPage"))
const ReportsPage = lazy(() => import("./pages/ReportsPage"))
const DashboardPage = lazy(() => import("./pages/dashboard")); // "./pages/DashboardPage"
const ModuleDetailPage = lazy(() => import("./pages/ModuleDetailPage"));
const ItemEditorPage = lazy(() => import("./pages/ItemEditorPage"));
const TaskEditorPage = lazy(() => import("./pages/TaskEditorPage"));
const ScheduleEditorPage = lazy(() => import("./pages/ScheduleEditorPage"));
const DatasetPage = lazy(() => import("./pages/DatasetPage"));
const Webhooks = lazy(() => import("./pages/Webhooks"));
const FormWebhook = lazy(() => import("./pages/Webhooks/FormWebhook"));
const DatasetDetailPage = lazy(() => import("./pages/DatasetPage/Detail"));
const NotificationPage = lazy(() => import("./pages/NotificationPage"));
const RolesPermissions = lazy(() => import("./modules/Settings/pages/RolesPermissions"));
const TeamManagement = lazy(() => import("./modules/Settings/pages/TeamManagement"));
const TeamDetailPage = lazy(() => import("./modules/Settings/pages/TeamManagement/detail"));
const DataConnectorPage = lazy(() => import("./pages/DataConnectorPage"));
const AddDataConnectorPage = lazy(() => import("./pages/DataConnectorPage/AddDataConnectorPage"));

const TasksPage = lazy(() => import('./pages/tasks'));
const DashboardListPage = lazy(() => import('./pages/dashboard-list'));
const ReportPage = lazy(() => import('./modules/Reports/Pages'));
const AuditPage = lazy(() => import('./modules/AuditTrail/pages'));
const ReportDesignerPage = lazy(() => import('./pages/report-designer'));
const ReportViewerPage = lazy(() => import('./pages/report-viewer'));

const EasyQueryAdvanceSearchPage = lazy(() => import('./pages/EasyQueryAdvanceSearchPage'));
const ComingSoonPage = lazy(() => import('./pages/ComingSoonPage'));
const PermalinkPage = lazy(() => import('./pages/PermalinkPage'));
const ElsaPage = lazy(() => import('./pages/ElsaPage'));

// DEVS ONLY: 
// const TestComponent = lazy(() => import("./pages/TestComponent"));

export default function BasePage() {
	const dispatch = useDispatch();
	const { isFullUser, isConsultant, isSysAdmin, isAdministrator } = useSelector(({auth}) => auth.config || {});
	const { userInfo } = useSelector(({ optionsReducer }) => optionsReducer || {});

	useEffect(() => {
		dispatch(preferenceActions.getUserPreference());
		dispatch(organizationActions.getCurrentOrganization());
		dispatch(preferenceActions.getQuickLaunchApps());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []) // [] - is required if you need only one call
	// https://reactjs.org/docs/hooks-reference.html#useeffect

	let sysAdminRoutes = [		
		{path:"/process-engine", component:ElsaPage},
		{path:"/app/audit", component:AuditPage}
	]

	let restrictedRoutes = [
		{path:"/business-intelligence", component:ReportPage}, //  /reports
		{path:"/business-intelligence-landing", component:ComingSoonPage}, //  /reports
		{path:"/list-dashboard", component:DashboardListPage},
		{path:"/report-designer", component:ReportDesignerPage},
		{path:"/report-viewer", component:ReportViewerPage},
		{path:"/reports", component:EasyQueryAdvanceSearchPage}, // reports/ad-hoc-report
		{path:"/inquiry", component:EasyQueryAdvanceSearchPage}, //reports/advance-search
		{path:"/dashboard", component:DashboardPage},
		{path:"/dashboard-landing", component:ComingSoonPage},
		{path:"/bi-reports", component:ComingSoonPage},
		{path:"/permalink/file", component:PermalinkPage},
		{exact:true, path:"/form-editor", component:AppEditorPage},
		{path:"/form-editor/show", component:FormEditorPage},
		{exact: true, path:"/referencedata", component:DatasetPage},
		{path:"/referencedata/:id", component:DatasetDetailPage}, // /:view
		{exact: true, path:"/data-connector", component:DataConnectorPage},
		{exact: true, path:"/data-connector/:dcType", component:AddDataConnectorPage},
		{exact: true, path:"/data-connector/:dcType/:recordId", component:AddDataConnectorPage},
	]

	let restrictedAdminRoutes = [
		{path:"/settings/menu", component:ApplicationMenuPage},
		{path:"/app/organization", component:OrganizationInfo}, // organizationInfo
		{path:"/settings/roles-permissions", component:RolesPermissions},
		{exact: true, path:"/settings/user-management", component:ApplicationUserPage},
		{exact: true, path:"/settings/user-management/:license", component:ApplicationUserPage},
		{path:"/settings/user-detail", component:UserDetailPage},
		{path:"/settings/organization", component:OrganizationsPage},
		{exact:true, path:"/settings/team-management", component:TeamManagement},
		{path:"/settings/team-management/detail", component:TeamDetailPage},
		{path:"/organization", component:OrganizationDetailPage}, // /:ID
		{exact: true, path:"/module-categories", component:ApplicationMenuPage},
		{exact: true, path:"/webhooks", component:Webhooks},
		{exact: true, path:"/webhooks/add", component:FormWebhook},
		{exact: true, path:"/webhooks/:id", component:FormWebhook}
	]

	if(isSysAdmin || isAdministrator || isConsultant){
		restrictedRoutes = restrictedRoutes.concat(restrictedAdminRoutes)
	}

	let unrestrictedRoutes = [
		{path:"/tasks", component:TasksPage},
		{path:"/app/user/profile", component:UserProfile},
		{path:"/applications", component:ReportsPage},
		{path:"/module/:moduleId", component:ModuleDetailPage},
		{exact: true, path:"/item-editor/:moduleId", component:ItemEditorPage},
		{path:"/item-editor/:moduleId/:recordId/:taskId?", component:ItemEditorPage},
		{path:"/task-editor/:moduleId/:recordId?/:taskId?", component:TaskEditorPage},
		{exact: true, path:"/schedule-editor/:moduleId/:recordId/", component:ScheduleEditorPage},
		{exact: true, path:"/schedule-editor/:moduleId", component:ScheduleEditorPage},
		{path:"/notifications", component:NotificationPage},
	]

	const handleDefaultLaunchUrl = () => {
		let route = '/tasks'
		const launchPage = !isEmpty(userInfo) ? userInfo.preference?.launch_page : null
		if (isFullUser || isAdministrator) {
			if (launchPage) {
				if (launchPage.type.toLowerCase() === 'dashboard&reports') {
					route = '/dashboard'
				}else if(launchPage.type.toLowerCase() === 'quickreport') {
					route = '/inquiry'
				}else if(launchPage.type.toLowerCase() === 'apps') {
					route = '/applications'
				}
			}
		}
		return <Redirect exact from="/" to={route} />
	}
	
	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				{handleDefaultLaunchUrl()}
				{
					unrestrictedRoutes.map((item, index) => {
						return <AuthorizeRoute key={index} path={item.path} component={item.component} exact={item.exact} />
					})
				}
				{
					/**
					 * Restrict credit users from accessing
					 * these routes:
					 */

					isFullUser || isConsultant
						? (
							restrictedRoutes.map((item, index) => {
								return <AuthorizeRoute key={index} path={item.path} component={item.component} exact={item.exact} />
							})
						)
						: <Redirect to={'/404'} />
				}
				{
					isSysAdmin ?
						(
							sysAdminRoutes.map((item, index) => {
								return <AuthorizeRoute key={index} path={item.path} component={item.component} exact={item.exact} />
							})
						)
						: <Redirect to={'/404'} />
				}
			</Switch>
		</Suspense>
	);
}